







































































import axios from 'axios'
import {Component, Ref, Vue} from 'vue-property-decorator'
import draggable from 'vuedraggable/dist/vuedraggable.umd.js'
import Ckeditor from '~/components/ckeditor.vue'
import {createRequest, getServerBaseURL} from '~/utils/network-request'

export class OfflinePaymentMethod {
    icon: string = ''
    title: string = ''
    content: string = ''
    show: boolean = true
}

@Component({
    components: {
        Ckeditor,
        draggable
    }
})
export default class AppOfflinePayments extends Vue {
    org: string
    data: OfflinePaymentMethod[] = []

    editing: OfflinePaymentMethod = new OfflinePaymentMethod()
    editingContent: string = ''
    uploading: OfflinePaymentMethod = new OfflinePaymentMethod()
    showEditor: boolean = false

    @Ref()
    fileinput: HTMLInputElement

    get changed() {
        return this.org !== JSON.stringify(this.data)
    }

    addMethod() {
        this.data.push(new OfflinePaymentMethod())
    }

    remove(m: OfflinePaymentMethod) {
        this.data.splice(this.data.findIndex(dm => dm === m), 1)
    }

    startEdit(m: OfflinePaymentMethod) {
        this.editingContent = m.content
        this.editing = m
        this.showEditor = true
    }

    confirmEdit() {
        this.editing.content = this.editingContent
        this.showEditor = false
    }

    async created() {
        const res = await createRequest('/offline-payment-methods/data', 'get').send(false)
        this.data = res.data.payment_methods
        this.org = JSON.stringify(this.data)
    }

    async save() {
        await createRequest('/offline-payment-methods/data', 'post', {}, {
            payment_methods: this.data
        }).send()
        this.org = JSON.stringify(this.data)
        this.$message({message: 'Saved', type: 'success'})
    }

    async uploadIcon(m: OfflinePaymentMethod) {
        this.uploading = m
        this.fileinput.click()
    }

    async uploaded(e: Event) {
        e.preventDefault()
        if (!e.target) return
        if (!e.target['files']) return

        let uploadUrl = ''
        const baseUrl = getServerBaseURL()
        if (baseUrl.startsWith('http://') || baseUrl.startsWith('https://')) {
            uploadUrl = `${baseUrl}offline-payment-method/icon`
        } else {
            uploadUrl = `https://${baseUrl}offline-payment-method/icon`
        }

        let form = new FormData()
        form.append('file', e.target['files'][0])

        const loading = this.$loading({
            target: '.micon'
        })
        const token = await this.$auth.getAccessToken()

        const res = await axios({
            method: 'post',
            url: uploadUrl,
            data: form,
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'multipart/form-data'
            }
        })

        this.uploading.icon = res.data['file_url']
        loading.close()
    }
}
