































import {Component, Vue} from 'vue-property-decorator'
import RefereeReward from '~/components/setting/referee-reward.vue'
import AppVersion from '~/components/setting/app-version.vue'
import AppOfflinePayments from '~/components/setting/app-offline-payments.vue'
import OfflinePayments from '~/components/setting/offline-payments.vue'
import {AdminPermission} from '~/utils/permissions'

@Component({
    components: {
        RefereeReward,
        AppVersion,
        AppOfflinePayments,
        OfflinePayments
    },
    metaInfo() {
        return {
            title: 'Setting'
        }
    }
})

export default class Setting extends Vue {
    static permission = AdminPermission.Setting
}

