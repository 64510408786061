



































import {Component, Vue} from 'vue-property-decorator'
import {createRequest} from '~/utils/network-request'

@Component({
    components: {}
})
export default class AppVersion extends Vue {
    androidMinOrg = ''
    iosMinOrg = ''
    androidMinCodeOrg = 0

    androidMin = ''
    iosMin = ''
    androidMinCode = 0

    created() {
        createRequest('/min-version/android', 'get').send()
            .then((res) => {
                this.androidMinOrg = res.data.version
                this.androidMin = this.androidMinOrg
            })
        createRequest('/min-version/ios', 'get').send()
            .then((res) => {
                this.iosMinOrg = res.data.version
                this.iosMin = this.iosMinOrg
            })
        createRequest('/min-code/android', 'get').send()
            .then((res) => {
                this.androidMinCodeOrg = res.data.code
                this.androidMinCode = this.androidMinCodeOrg
            })
    }
    async saveAndroidVersion() {
        await createRequest('/min-version/android', 'post', {}, {
            version: this.androidMin
        }).send()
        this.androidMinOrg = this.androidMin
    }
    async saveIOSVersion() {
        await createRequest('/min-version/ios', 'post', {}, {
            version: this.iosMin
        }).send()
        this.iosMinOrg = this.iosMin
    }
    async saveAndroidCode() {
        await createRequest('/min-code/android', 'post', {}, {
            code: this.androidMinCode
        }).send()
        this.androidMinCodeOrg = this.androidMinCode
    }
}
