

























import {Component, Vue} from 'vue-property-decorator'
import {createRequest} from '~/utils/network-request'
import CourseSelect from '~/components/select/course-select.vue'

@Component({
    components: {
        CourseSelect,
    }
})

export default class RefereeReward extends Vue {
    diamonds = 0
    coins = 0
    course = ''

    org = ''
    saveOrg() {
        this.org = this.currentStatus
    }

    get currentStatus() {
        return `coin${this.coins}diamond${this.diamonds}course${this.course}`
    }

    get changed() {
        return this.org !== this.currentStatus
    }
    async created() {
        const res = await createRequest('/referral-data', 'get').send()
        this.diamonds = res.data.referee_rewards.diamonds
        this.coins = res.data.referee_rewards.coins
        this.course = res.data.referee_rewards.course.course_id
        this.saveOrg()
    }
    async save() {
        await createRequest('/referee-reward', 'post', {}, {
            "coins": this.coins,
            "diamonds": this.diamonds,
            "course": this.course
        }).send()
        this.saveOrg()
        this.$message({
            message: `Saved!`,
            type: 'success'
        })
    }
}

