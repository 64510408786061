











import {Component, Vue} from 'vue-property-decorator'
import {createRequest} from '~/utils/network-request'
import Ckeditor from '~/components/ckeditor.vue'

@Component({
    components: {
        Ckeditor
    }
})
export default class AppOfflinePayments extends Vue {
    htmlText = ''
    org = ''

    get changed() {
        return this.org !== this.htmlText
    }
    async created() {
        const res = await createRequest('/offline-payment-methods', 'get').send(false)
        this.htmlText = res.data.payment_methods
        this.org = this.htmlText
    }
    async save() {
        await createRequest('/offline-payment-methods', 'post', {}, {
            payment_methods: this.htmlText
        }).send()
        this.org = this.htmlText
        this.$message({message: 'Saved', type: 'success'})
    }
}
